@font-face {
  font-family: "Betterday";
  src: url("./fonts/Betterday.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* background-color: #345C65; */
  background-color: #3e5b63;
}

.upperContainer {
  height: 40vh;
  background-color: #b25537;
  position: relative;
  transform: skewY(-5deg);
  margin-top: -15vh;
  z-index: 3;
}

.upperSecondary {
  background-color: #82a778;
}

.custom-transition {
  transition: all 0.4s ease-in-out;
}

/* Left Circle */
.circle-container {
  height: inherit;
  width: 10vw;
  margin-left: 25%;
  background-color: #345c65;
  position: relative;
  transform: skewX(5deg);
  transition: background-color 0.4s;
}

.circle-container.hide {
  animation: fadeout 0.4s ease-in-out forwards;
}

.circle-container.show {
  animation: fadein 0.4s ease-in-out forwards;
}

.circle {
  width: 10.25vw;
  height: 10.25vw;
  background-color: #c5ab7a;
  border-radius: 50%;
  position: absolute;
  margin-left: -0.125vw;
  bottom: calc(-10.25vw / 2);
  cursor: pointer;
  padding: 10%;
}

.circle.hide {
  animation: fadeout 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

.circle.show {
  animation: fadein 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

/* Right Circle */
.circle-container-right {
  height: inherit;
  width: 10vw;
  margin-left: 33.3%;
  background-color: #345c65;
  position: relative;
  transform: skewX(5deg);
  transition: background-color 0.4s ease-in-out;
}

.circle-right {
  width: 10.25vw;
  height: 10.25vw;
  background-color: #c5ab7a;
  border-radius: 50%;
  position: absolute;
  margin-left: -0.125vw;
  bottom: calc(-10.25vw / 2);
  z-index: 100;
}

/* Center Container */
.center-container {
  height: 60vh;
  justify-content: center;
  text-align: center;
  padding-top: 10vh;
}

.contact-container {
  overflow-y: auto !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.contact-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.contact-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.gold-text {
  color: #c5ab7a;
}

a.gold-text:hover {
  color: #c5ab7a;
}

.footer-text {
  letter-spacing: 0.8rem;
  font-weight: normal;
  font-family: proxima-nova, sans-serif;
}

.containerMenu {
  cursor: pointer;
  transform: skewY(5deg);
  margin-left: 30%;
  margin-top: calc(50% - 7.5px);
}

.bar1,
.bar3 {
  width: 60%;
  height: 7.5px;
  background-color: #345c65;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 20%;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -5px);
  transform: rotate(45deg) translate(-5px, -5px);
}

.overlay {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c5ab7a;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}

.gold-bg {
  background-color: #c5ab7a;
}

.overlay-center-container {
  height: 100vh;
  justify-content: center;
  text-align: center;
  padding-top: 33.3vh;
}

.overlay.show {
  visibility: visible;
  animation: fadein 0.4s forwards ease-in-out;
  -moz-animation: fadein 0.4s forwards ease-in-out; /* Firefox */
  -webkit-animation: fadein 0.4s forwards ease-in-out; /* Safari and Chrome */
  -o-animation: fadein 0.4s forwards ease-in-out; /* Opera */
}

.overlay.hide {
  animation: fadeout 0.4s forwards ease-in-out;
  -moz-animation: fadeout 0.4s forwards ease-in-out;
  -webkit-animation: fadeout 0.4s forwards ease-in-out;
  -o-animation: fadeout 0.4s forwards ease-in-out;
}

@keyframes fadein {
  from {
    opacity: 0;
    visibility: visible;
    z-index: 0;
  }
  to {
    opacity: 1;
    display: block;
    z-index: 2;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}

.menu-item {
  font-size: 1.75rem;
  color: #345c65;
  margin-bottom: 2rem;
  letter-spacing: 0.5rem;
  cursor: pointer;
  font-family: proxima-nova, sans-serif;
}

/* Small devices (phones, 992px and down) */
@media only screen and (max-width: 992px) {
  /* Left Circle */
  .circle-container {
    width: 20vw;
    margin-left: 25%;
  }

  .circle {
    width: 20.25vw;
    height: 20.25vw;
    margin-left: -0.125vw;
    bottom: calc(-20.25vw / 2);
  }

  /* Right Circle */
  .circle-container-right {
    width: 20vw;
    margin-left: 33.3%;
  }

  .circle-right {
    width: 20.25vw;
    height: 20.25vw;
    border-radius: 50%;
    bottom: calc(-20.25vw / 2);
  }

  .containerMenu {
    margin-top: calc(50% - 6px);
  }

  .bar1,
  .bar3 {
    height: 3px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-2px, 3px);
    transform: rotate(-45deg) translate(-1px, 3px);
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -5px);
    transform: rotate(45deg) translate(-3px, -5px);
  }

  .title-font {
    font-size: 8rem !important;
  }

  #gmap_canvas {
    width: 80vw !important;
    height: 30vh !important;
  }

  .contact-container {
    margin-top: 7.5vh;
  }
}

.menu-title {
  font-weight: normal;
  font-family: "Betterday", sans-serif;
  font-size: 5rem;
}

.menu-item-title {
  font-size: 1.35rem;
}

.menu-desc {
  font-weight: lighter;
  line-height: 1.2rem;
  font-size: 0.9rem;
}

.transparent {
  opacity: 0;
}

.font-light {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.title-font {
  font-family: "Betterday", sans-serif;
  font-size: 12rem;
  font-weight: 300;
  line-height: 0.6;
}

.font-medium {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-thin {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
}

#gmap_canvas {
  width: 40vw;
  height: 30vh;
}

.c-pointer {
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .menu-item {
    font-size: 1.5rem;
  }

  .menu-title {
    font-size: 12vw !important;
  }

  .footer-text {
    font-size: 1.25rem;
    letter-spacing: 0.5rem;
  }

  .contact-text {
    font-size: 1.25rem;
  }
}

/* Extra large devices (large laptops and desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bar1,
  .bar3 {
    height: 4px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-2px, 3px);
    transform: rotate(-45deg) translate(-4px, 2px);
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -5px);
    transform: rotate(45deg) translate(-3px, -5px);
  }
}
